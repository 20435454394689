import React, { useEffect, useState } from 'react';
import '@expo/match-media';
import { useMediaQuery } from 'react-responsive';
import { Text, View } from 'react-native';
import * as Permissions from 'expo-permissions';
import { Feather } from '@expo/vector-icons';
import QrReader from 'react-qr-reader';
import { useRoute } from '@react-navigation/native';

import api from '../../services/api';

import {
  EventContainer,
  EventHeader,
  EventDescription,
  EventTitle,
  EventOwner,
  Background,
  BackgroundImageOpacity,
  InvitationBackground,
  Logo,
  QrContainer,
  BlurBackground,
} from './styles';
import Presence from '../../components/Presence';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface Event {
  id: string;
  name: string;
  about: string;
  owner: string;
  event_date: string;
  confirmation_limit_date: string;
  place: string;
  latitude: string;
  longitude: string;
  images: Array<{
    id: string;
    url: string;
  }>;
}

interface Image {
  id: string;
  url: string;
}

interface Guests {
  id: string;
  name: string;
  phone: string;
  confirmed: boolean;
  presence: boolean;
  created_at: string;
  updated_at: string;
}

interface RouteParams {
  id: string;
}

const Camera: React.FC<RouteParams> = () => {
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const [event, setEvent] = useState<Event>();
  const [eventImage, setEventImage] = useState<Image>();
  const [presence, setPresence] = useState(false);
  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState('');
  const [iconBg, setIconBg] = useState('');
  const [color, setColor] = useState('');
  const [offlineGuests, setOfflineGuests] = useState<Guests[]>([]);
  const [token, setToken] = useState('');

  const route = useRoute();
  const { id } = route.params as RouteParams;

  useEffect(() => {
    async function getToken() {
      const token = await AsyncStorage.getItem('@Beni/Token');

      if (token) {
        setToken(token);
      }
    }

    async function getOfflineEvent() {
      const offlineEvent = await AsyncStorage.getItem(`@Beni/Event-${id}`);

      if (offlineEvent) {
        const parsedEvent = await JSON.parse(offlineEvent);

        setEvent(parsedEvent);

        return;
      }

      api.get(`/events/${id}`).then((response) => {
        setEvent(response.data);
      });
    }

    async function getOfflineGuests() {
      const offlineGuests = await AsyncStorage.getItem(`@Beni/Guests-${id}`);

      if (offlineGuests) {
        const parsedGuests = await JSON.parse(offlineGuests);

        setOfflineGuests(parsedGuests);

        return;
      }

      api.get(`/events/${id}`).then((response) => {
        setOfflineGuests(response.data.guests);
      });
    }

    getToken();
    getOfflineEvent();
    getOfflineGuests();

    (async () => {
      const { status } = await Permissions.askAsync(Permissions.CAMERA);

      setHasCameraPermission(status === 'granted');
    })();
  }, []);

  useEffect(() => {
    if (presence) {
      setTimeout(() => {
        setPresence(false);
        return;
      }, 3000);
    }

    return;
  }, [presence]);

  useEffect(() => {
    event?.images.map((image) => {
      return setEventImage(image);
    });
  }, [event]);

  const isMobileDevice = useMediaQuery({
    query: '(max-width: 520px)',
  });

  const isDesktopDevice = useMediaQuery({
    query: '(min-width: 800px)',
  });

  if (hasCameraPermission === null) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text>Solicitando acesso à Câmera</Text>
      </View>
    );
  }

  if (hasCameraPermission === false) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text>Sem acesso à Câmera, verifique as permissões</Text>
      </View>
    );
  }

  const handleBarCodeScanned = (data: any) => {
    if (!data) {
      return;
    }

    const isQRCodeValid = data.includes('beni');

    if (!isQRCodeValid) {
      return;
    }

    const guestId = data
      .replaceAll('https://beni-convite-digital.herokuapp.com/guests/', '')
      .replaceAll('/confirm', '');

    const guests = [...offlineGuests];
    const guest = offlineGuests.filter(({ id }) => id === guestId);
    const guestIndex = offlineGuests.findIndex(({ id }) => id === guestId);

    const checkGuestStatus = () => {
      guest.map(async (guest) => {
        if (!guest) {
          setMessage('Convidado(a) não encontrado(a)!');
          setColor('#e84118');
          setIcon('x');
          setIconBg('#c23616');
          setPresence(true);

          return;
        }

        if (guest.confirmed === true && guest.presence === false) {
          setMessage(`Seja bem vindo(a), ${guest.name}!`);
          setColor('#4cd137');
          setIcon('check');
          setIconBg('#44bd32');
          setPresence(true);

          guests[guestIndex] = {
            id: guest.id,
            name: guest.name,
            phone: guest.phone,
            confirmed: guest.confirmed,
            presence: true,
            created_at: guest.created_at,
            updated_at: guest.updated_at,
          };

          setOfflineGuests(guests);

          await AsyncStorage.removeItem(`@Beni/Guests-${id}`);
          await AsyncStorage.setItem(
            `@Beni/Guests-${id}`,
            JSON.stringify(guests)
          );

          return;
        }

        if (guest.presence) {
          setMessage('Convidado(a) já está no evento!');
          setColor('#fbc531');
          setIcon('alert-triangle');
          setIconBg('#e1b12c');
          setPresence(true);

          return;
        }

        // if (!guest || !guest.confirmed) {
        //   setMessage('Convidado(a) não encontrado(a)!');
        //   setColor('#e84118');
        //   setIcon('x');
        //   setIconBg('#c23616');
        //   setPresence(true);

        //   return;
        // }

        setMessage('Convidado(a) não encontrado(a)!');
        setColor('#e84118');
        setIcon('x');
        setIconBg('#c23616');
        setPresence(true);

        console.log(guest);

        return;
      });
    };

    checkGuestStatus();
  };

  return (
    <>
      <Background />
      <BackgroundImageOpacity source={{ uri: eventImage?.url }} />
      <InvitationBackground>
        <BlurBackground intensity={80}>
          {isMobileDevice ? (
            <EventHeader
              key={eventImage?.id}
              source={{ uri: eventImage?.url }}
              style={{ height: '200px' }}
            />
          ) : isDesktopDevice ? (
            <EventHeader
              key={eventImage?.id}
              source={{ uri: eventImage?.url }}
              style={{ height: '480px' }}
            />
          ) : (
            <EventHeader
              key={eventImage?.id}
              source={{ uri: eventImage?.url }}
              style={{ height: '320px' }}
            />
          )}

          <EventContainer>
            <EventDescription>
              <EventTitle>{event?.name}</EventTitle>
            </EventDescription>

            <EventOwner>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
              >
                <Feather name='user' size={20} color='#9cc1a6' />
                <Text style={{ marginLeft: 8, fontSize: 16 }}>Organizador</Text>
              </View>
              <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
                {event?.owner}
              </Text>
            </EventOwner>

            <Logo source={require('../../assets/images/logo.svg')} />

            <QrContainer
              style={
                isMobileDevice
                  ? { width: '80%', height: '80%' }
                  : isDesktopDevice
                  ? { width: '40%', height: '40%' }
                  : { width: '60%', height: '60%' }
              }
            >
              <QrReader
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: 9999,
                }}
                delay={500}
                showViewFinder
                onError={() => {
                  alert('Erro inesperado.');
                }}
                resolution={600}
                facingMode='user'
                onScan={handleBarCodeScanned}
              />
            </QrContainer>
          </EventContainer>
        </BlurBackground>
      </InvitationBackground>
      {presence && (
        <Presence color={color} message={message} icon={icon} iconBg={iconBg} />
      )}
    </>
  );
};

export default Camera;
