import React, { useEffect, useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { format, parseISO } from 'date-fns';
import { Feather } from '@expo/vector-icons';

import api from '../../services/api';

import { Container, ListWrapper, List, Header } from './styles';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface Event {
  id: string;
  name: string;
  about: string;
  owner: string;
  event_date: string;
}

interface EventWithGuests {
  id: string;
  name: string;
  about: string;
  owner: string;
  event_date: string;
  guests: {
    id: string;
    name: string;
    phone: string;
    confirmed: boolean;
    presence: boolean;
  };
}

const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [event, setEvent] = useState<EventWithGuests>();

  const navigation = useNavigation();

  useEffect(() => {
    async function getToken() {
      try {
        const token = await AsyncStorage.getItem('@Beni/Token');

        if (!token) {
          navigation.navigate('SignIn');
          return;
        }

        api({
          method: 'get',
          url: 'events',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            setEvents(response.data);
          })
          .catch(async () => {
            try {
              await AsyncStorage.removeItem('@Beni/Token');
              await AsyncStorage.removeItem('@Beni/User');
              navigation.navigate('SignIn');
            } catch (err) {
              throw err;
            }
          });
      } catch (error) {
        throw error;
      }
    }

    getToken();
  }, []);

  // async function handleStoreEventOffline() {

  // }

  // async function handleLogout() {
  //   try {
  //     await AsyncStorage.removeItem('@Beni/Token');
  //     await AsyncStorage.removeItem('@Beni/User');
  //     navigation.navigate('SignIn');
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  return (
    <Container>
      <Header>
        <Image
          source={require('../../assets/images/logo.svg')}
          style={{ marginVertical: 28, width: '340px', height: '165px' }}
        />
      </Header>

      <ListWrapper>
        <List>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingHorizontal: 16,
              paddingVertical: 8,
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>Nome do Evento</Text>
            <View
              style={{
                flexDirection: 'row',
                width: '40%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontWeight: 'bold', width: '50%' }}>Data</Text>
              <Text style={{ fontWeight: 'bold', maxWidth: 200 }}>Atual.</Text>
            </View>
          </View>

          {events.map((event) => (
            <TouchableOpacity
              key={event.id}
              onPress={() => {
                navigation.navigate('Camera', {
                  id: event.id,
                });
              }}
              style={{
                width: '100%',
                height: '60px',
                backgroundColor: '#555',
                marginVertical: '4px',
                paddingHorizontal: 16,
                paddingVertical: 8,
                borderRadius: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#d9e4dc' }}>{event.name}</Text>
              <View
                style={{
                  flexDirection: 'row',
                  width: '40%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#d9e4dc' }}>
                  {format(parseISO(event.event_date), 'dd/MM/yyyy')}
                </Text>
                <Feather
                  onPress={async () => {
                    await api.get(`events/${event.id}`).then((response) => {
                      AsyncStorage.removeItem(`@Beni/Event-${event.id}`);
                      AsyncStorage.setItem(
                        `@Beni/Event-${event.id}`,
                        JSON.stringify(response.data)
                      );

                      AsyncStorage.removeItem(`@Beni/Guests-${event.id}`);
                      AsyncStorage.setItem(
                        `@Beni/Guests-${event.id}`,
                        JSON.stringify(response.data.guests)
                      );
                    });
                  }}
                  name='refresh-cw'
                  size={20}
                  color='#d9e4dc'
                  style={{ maxWidth: 200 }}
                />
              </View>
            </TouchableOpacity>
          ))}
        </List>
      </ListWrapper>
    </Container>
  );
};

export default Events;
