import styled from 'styled-components/native';

export const Container = styled.View`
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  background: #d9e4dc;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

export const Form = styled.View`
  width: 100%;
  max-width: 400px;
  height: auto;
  margin:0 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const Input = styled.View`
  width: 100%;
  height: 40px;
  margin: 16px 0;
  border-bottom-width: 2px;
  border-bottom-color: #555;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TextInput = styled.TextInput`
  width: 100%;
  height: 60px;
  background: transparent;
  border-width: 0px;
  border: none;
  padding: 16px 8px;
  margin-left: 8px;
  color: #555;
`

export const Label = styled.View`
  width: 100px;
  padding: 4px;
  color: #fbf6f0;
  border-radius: 8px 0 0 8px;
  height: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.TouchableOpacity`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: #fbf6f0;
  background: #555;
  border: 0;
  margin: 16px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;