import styled from 'styled-components/native';

interface ContainerProps {
  bgColor: boolean;
}

export const Container = styled.View<ContainerProps>`
  max-width: 100vw;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  z-index: 99;

  background: ${props => `${props.bgColor}`};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const Title = styled.Text`
  font-size: 28px;
  color: #fff;
`; 