import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Camera from './pages/Camera';
import SignIn from './pages/SignIn';
import EventList from './pages/EventList';

const Routes: React.FC = () => {
  const Stack = createStackNavigator();

  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          options={{ headerShown: false }}
          name='SignIn'
          component={SignIn}
        />
        <Stack.Screen
          options={{ headerShown: false }}
          name='EventList'
          component={EventList}
        />
        <Stack.Screen
          options={{ headerShown: false }}
          name='Camera'
          component={Camera}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Routes;
