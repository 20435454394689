import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import api from '../../services/api';

import { Container, Form, Input, Button, TextInput } from './styles';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigation = useNavigation();

  useEffect(() => {
    const getToken = async () => {
      await AsyncStorage.getItem('@Beni/Token');
    };

    if (getToken()) {
      navigation.navigate('EventList');
      return;
    }
  }, []);

  async function handleSubmit() {
    const data = {
      email,
      password,
    };

    await api
      .post('sessions', data)
      .then(async (response) => {
        const { user, token } = response.data;

        await AsyncStorage.setItem('@Beni/User', user);
        await AsyncStorage.setItem('@Beni/Token', token);

        navigation.navigate('EventList');
      })
      .catch(() => {
        alert('E-mail ou senha inválido.');
        return;
      });
  }

  return (
    <Container>
      <Form>
        <Image
          source={require('../../assets/images/logo.svg')}
          style={{ margin: '28px 0 8px', width: '340px', height: '165px' }}
        />
        <Input>
          <Feather size={32} color='#555' name='user' />
          <TextInput
            value={email}
            onChangeText={setEmail}
            placeholder='Usuário'
          />
        </Input>
        <Input>
          <Feather size={32} color='#555' name='lock' />
          <TextInput
            secureTextEntry
            value={password}
            onChangeText={setPassword}
            placeholder='Senha'
          />
        </Input>

        <Button onPress={handleSubmit}>
          <Feather size={32} color='#fbf6f0' name='arrow-right' />
        </Button>
      </Form>
    </Container>
  );
};

export default SignIn;
