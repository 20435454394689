import React from 'react';
import { Feather } from '@expo/vector-icons';
import { View } from 'react-native';

import { Container, Title } from './styles';

interface PageProps {
  color: any | string;
  message: string;
  icon: any | string;
  iconBg: any | string;
}

const Presence: React.FC<PageProps> = ({ color, icon, iconBg, message }) => {
  return (
    <Container
      bgColor={color}
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <Title>{message}</Title>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 80,
          height: 80,
          borderRadius: 40,
          backgroundColor: `${iconBg}`,
          marginTop: 30,
        }}
      >
        <Feather name={icon} size={40} color='#fff' />
      </View>
    </Container>
  );
};

export default Presence;
