import styled from 'styled-components/native';
import { BlurView } from 'expo-blur';

export const Background = styled.View`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background: #fff;
`;

export const BackgroundImageOpacity = styled.Image`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  object-fit: cover;
  opacity: 0.25;
`;

export const InvitationBackground = styled.View`
  position: fixed;
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const BlurBackground = styled(BlurView)`
  max-width: 80%;
  width: 100%;
  height: auto;
  max-height: 95%;
  background: #fff;
  font-weight: normal;
  border-radius: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const EventHeader = styled.Image`
  width: 100%;
  border-radius: 40px;
  z-index: 4;
  object-fit: cover;
`;

export const EventContainer = styled.View`
  color: #545454;
  padding: 24px;
  width: 100%;
  height: auto;
  background: transparent;
  border-radius: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 8;
`;

export const Separator = styled.View`
  width: 80%;
  height: 2px;
  margin: 0 24px;
  background: #ffffff;
  opacity: 1;
`;

export const EventDescription = styled.View`
  width: 100%;
  height: auto;
  border-radius: 16px;
  background: rgba(255,255,255, 0.30);
  padding: 8px;
  margin: 8px;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const EventTitle = styled.Text`
  font-size: 18px;
  font-weight: bold;
`;

export const EventLocation = styled.View`
  margin-top: 8px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #555;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const EventOwner = styled.View`
  width: 100%;
  text-decoration: none;
  color: #555;
  font-weight: bold;
  padding: 8px 0;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QrContainer = styled.View`
  margin: 8px 0;
  z-index: 10;

  display: flex;
  justify-content: center;
`;

export const Logo = styled.Image`
  width: 72px;
  height: 35px;
  margin: 8px 0;
`;