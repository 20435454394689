import styled from 'styled-components/native';

export const Container = styled.View`
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background: #d9e4dc;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;
`;

export const Header = styled.View`
  width: 100%;
  max-width: 300px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListWrapper = styled.View`
  width: 100%;
  max-width: 600px;
  height: auto;
  background: transparent;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const List = styled.View`
  list-style: none;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;